<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['แพลตฟอร์ม', 'deploy platform']" />
    <div style="width: 400px">
      <v-select
        v-if="platformList"
        label="name"
        :options="platformList"
        v-model="form.platformId"
        :reduce="(name) => name.id"
        :disabled="!canEdit"
      ></v-select>
    </div>
    <button class="btn btn-primary mt-2" @click="deploy" v-show="canEdit">ตกลง</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      platformList: null,
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("platform_deploy_edit") 
    }
  },
  methods: {
    async deploy() {
      if (!this.form.platformId) {
        this.swal.warning("กรูณาเลือก platform")
        return
      }

      let platform = this.platformList.find(
        (item) => item.id === this.form.platformId
      )

      let confirm = await this.swal.confirm(
        "Deploy Platform",
        `ท่านต้องการ deploy platform ${platform.name} ใช่หรือไม่`
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "platform/deploy/" + this.form.platformId,
        })

        await this.swal.success()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  async mounted() {
    this.swal.processing()

    try {
      const res = await this.axios({
        method: "get",
        url: "platform/all",
      })

      res.data.data?.forEach((item) => {
        item.name = `${item.code} (${item.name})`
      })

      this.platformList = res.data.data

      this.swal.close()
    } catch (e) {
      this.swal.catchError(e)
    }
  },
}
</script>

<style scoped>
@import "~vue-select/dist/vue-select.css";

.v-select {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;
  --vs-search-input-bg: none;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
}
</style>